@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.memesGrid {
  display: grid;
  gap: 0.25rem;
  grid-template-columns: repeat(auto-fill, minmax(var(--min-image-size), 3fr));
  grid-auto-flow: row dense;
}

.rc-slider-handle:active {
  outline: none;
  border: none !important;
  box-shadow: none !important;
}

.gifInput {
  display: grid;
  align-items: center;
  align-content: center;
  justify-content: center;
  max-width: 300px;
  width: 250px;
  background: #9ecdff;
  padding: 1rem;
  margin-right: auto;
  margin-left: auto;
  border-radius: 1rem 1rem;
}

.memesCard {
  display: grid;
  border: solid black;
  height: var(--min-image-size);
  width: var(--min-image-size);
  border-radius: 1rem 1rem;
}

.nubTagButton {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background: #ffc0d8;
  border-radius: 0.5rem 0.5rem;
  font-family: "Poppins", sans-serif;
  color: #000; /* Black */
  text-decoration: none;
  border: transparent;
  height: 25px;
}

.nubText {
  font-size: 1.5rem;
  display: grid;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  text-align: center;
}

.nubInput {
  color: black;
  font-size: 1.5rem;
  background: #b7d9fe;
  padding: 0.75rem;
  border-radius: 1rem 1rem;
  font-family: "Poppins", sans-serif;
  border: transparent;
}

.nubInput::placeholder {
  color: black;
  border: transparent;
}

textarea:focus,
input:focus {
  outline: none;
  font-family: "Poppins", sans-serif;
}

.nubButton {
  border-radius: 12px;
  cursor: pointer;
  font-weight: 200;
  font-family: "Poppins", sans-serif;
  background: #b7d9fe;
  border: transparent;
  color: black;
  text-decoration: none;
}

.amountTotal {
  background: #ffc0d8;
  padding: 0.2rem;
  border-radius: 1rem;
}

.nubButton_2 {
  border-radius: 12px;
  cursor: pointer;
  font-style: normal;
  font-size: 1.5rem;
  border: transparent;
  color: black;
  text-decoration: none;
}

.memesCard:hover {
  box-shadow: 0px 0px 1.5rem #b7d9fe;
}

.tagsSection {
  display: flex;
  max-width: 500px;
  gap: 0.25rem;
  overflow-x: scroll;
  overflow-y: hidden;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80dvw;
  margin-left: auto;
  margin-right: auto;
  /* overflow-y: hidden; */
}

.base-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%; /* Example width - adjust as needed */
  max-width: 60%; /* Prevent going wider than 80% of viewport */
  margin-left: auto;
  margin-right: auto;
  right: 0;
  bottom: 0;
  height: 47dvw;
  padding-top: 23%;
}

.tv-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  padding-bottom: 100%;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  display: grid;
  pointer-events: none;
}

.tv-button {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  bottom: 0;
  height: 50px;
  background: transparent;
  border: transparent;
}

.save-button {
  height: 4dvw;
  cursor: pointer;
  border: none;
}

.arrow-button {
  height: 3.5dvw;
  cursor: pointer;
  border: none;
}

.nub_section {
  display: grid;
  justify-content: center;
  align-content: center;
  align-items: center;
}

@media (min-width: 320px) {
  .image-container {
    width: 100dvw;
  }

  .base-image {
    height: 60dvw;
    width: 70dvw;
    padding-top: 22%;
  }

  .tv-button {
    top: 80dvw;
  }

  .save-button {
    height: 7dvw;
  }

  .arrow-button {
    height: 5dvw;
  }
}

@media (min-width: 350px) {
  .image-container {
    width: 100dvw;
  }

  .base-image {
    height: 60dvw;
    width: 70dvw;
    padding-top: 22%;
  }

  .tv-button {
    top: 80dvw;
  }

  .save-button {
    height: 7dvw;
  }

  .arrow-button {
    height: 5dvw;
  }
}

@media (min-width: 375px) {
  .image-container {
    width: 100dvw;
  }

  .base-image {
    height: 60dvw;
    width: 70dvw;
    padding-top: 22%;
  }

  .tv-button {
    top: 80dvw;
  }

  .save-button {
    height: 7dvw;
  }

  .arrow-button {
    height: 5dvw;
  }
}

@media (min-width: 412px) {
  .tv-button {
    top: 80dvw;
  }

  .save-button {
    height: 7dvw;
  }

  .arrow-button {
    height: 5dvw;
  }
}

@media (min-width: 512px) {
  .tv-button {
    /* height: 50px; */
    top: 81dvw;
  }

  .save-button {
    height: 7dvw;
  }

  .arrow-button {
    height: 5dvw;
  }
}

@media (min-width: 650px) {
  .tv-button {
    top: 82dvw;
  }
}

@media (min-width: 768px) {
  .image-container {
    width: 100dvw;
  }

  .base-image {
    height: 60dvw;
    width: 70dvw;
    padding-top: 22%;
  }

  .tv-button {
    height: 50px;
    top: 84dvw !important;
  }

  .save-button {
    height: 8dvw;
    cursor: pointer;
    border: none;
  }

  .arrow-button {
    height: 6dvw;
    cursor: pointer;
    border: none;
  }
}

@media (min-width: 1024px) {
  .image-container {
    width: 50dvw;
  }

  .base-image {
    height: 31dvw;
    width: 30dvw;
    padding-top: 21%;
  }

  .tv-button {
    top: 40.5dvw !important;
  }

  .save-button {
    height: 4dvw !important;
    cursor: pointer;
    border: none;
  }

  .arrow-button {
    height: 3.5dvw !important;
    cursor: pointer;
    border: none;
  }
}

@media (min-width: 1100px) {
  .tv-button {
    top: 41dvw !important;
  }
}

@media (min-width: 1280px) {
  .tv-button {
    top: 42dvw !important;
  }
}

@media (max-width: 512px) {
  .memesCard {
    height: var(--min-image-size);
    width: var(--min-image-size);
  }

  .memesGrid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--min-image-size), 3fr)
    );
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #b7d9fe3f;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #b7d9fe;
  border-radius: 10px;
  border: 2px solid #b7d9fe3f;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #9ecdff;
}
